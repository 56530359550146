import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BehaviorSubject, first, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { getWeekEndDate, getWeekStartDate } from '@app/helpers/utils';
import { filterNotesByDateRange } from '@app/helpers/utils/data.utils';
import { StoreService } from './store.service';

const baseUrl = `${environment.apiUrl}`;
@Injectable({
    providedIn: 'root',
})
export class SchedulerV2Service {
    patientTabChanged: BehaviorSubject<boolean> = new BehaviorSubject(false);
    constructor(private http: HttpClient, private store: Store, private storeService: StoreService) {}

    getSchedulerData(facilityId, data) {
        return this.http.post(
            `${baseUrl}/scheduler/planner-weekly/${facilityId}`,
            { date: data },
            {
                withCredentials: true,
            }
        );
    }
    autoScheduler(facilityId, data) {
        return this.http.post(`${baseUrl}/scheduler/auto/${facilityId}`, data, {
            withCredentials: true,
        });
    }
    getManageLaborData(facilityId, data) {
        return this.http
            .post(
                `${baseUrl}/therapist/weekly/${facilityId}`,
                { date: data },
                {
                    withCredentials: true,
                }
            )
            .pipe(
                map((value: any) => {
                    // @TODO: This is a temporary hack, it will not be needed when new data is added after cleaning
                    const therapistList = [];
                    value.data.therapists.forEach((t: any) => {
                        if (t.user._id) therapistList.push(t);
                    });
                    value.data.therapists = therapistList;
                    return value;
                })
            );
    }
    getAllTherapistsFromCurrentDate(facilityId, data) {
        return this.http
            .post(
                `${baseUrl}/availability/all-therapists/${facilityId}`,
                { date: data },
                {
                    withCredentials: true,
                }
            )
            .pipe(
                map((value: any) => {
                    // @TODO: This is a temporary hack, it will not be needed when new data is added after cleaning
                    const therapistList = [];
                    value.data.therapists.forEach((t: any) => {
                        if (t.user._id) therapistList.push(t);
                    });
                    value.data.therapists = therapistList;
                    return value;
                })
            );
    }
    create(data) {
        return this.http.post(`${baseUrl}/caseload/`, data, {
            withCredentials: true,
        });
    }
    update(data) {
        return this.http.post(`${baseUrl}/caseload/${data.id}`, data, {
            withCredentials: true,
        });
    }
    mass_update(data) {
        return this.http.put(`${baseUrl}/caseload/mass-un-assign`, data, {
            withCredentials: true,
        });
    }
    createOrUpdate(data) {
        return this.http.post(`${baseUrl}/caseload/updateMany`, data, {
            withCredentials: true,
        });
    }
    getCaseLoadDataById(caseLoadId, facilityId, date) {
        return this.http.post<any>(
            `${baseUrl}/caseload/${caseLoadId}/${facilityId}`,
            { date },
            {
                withCredentials: true,
            }
        );
    }
    updatePlanDay(data) {
        return this.http.patch(`${baseUrl}/scheduler/update/plan-day`, data, {
            withCredentials: true,
        });
    }
    getPlannedDayOfPlannedTherapy(id) {
        return this.http.get<any>(`${baseUrl}/plan-therapy/${id}/plannedDays`, {
            withCredentials: true,
        });
    }
    unAssignUpdate(facilityId, data) {
        return this.http.post(`${baseUrl}/scheduler/undo-assignment/${facilityId}/${data.caseload}`, data, {
            withCredentials: true,
        });
    }
    updateAppointment(caseLoadId, facilityId, data) {
        return this.http.post(`${baseUrl}/caseload/bulk-appointments/${caseLoadId}/${facilityId}`, data, {
            withCredentials: true,
        });
    }
    reAssignUpdate(facilityId, data) {
        return this.http.post(`${baseUrl}/scheduler/redo-assignment/${facilityId}/${data.caseload}`, data, {
            withCredentials: true,
        });
    }
    caseloadPrint(data) {
        return this.http.post(`${baseUrl}/caseload/print`, data, {
            withCredentials: true,
        });
    }
    patientSessionUpdated(facilityId, data) {
        this.storeService.setLoadingState(true);
        this.getSchedulerData(facilityId, getWeekStartDate(new Date(data.date)))
            .pipe(first())
            .subscribe((result: any) => {
                this.storeService.setSchedulerCalendar(new Date(data.date));
                result.data.patients = filterNotesByDateRange(
                    result.data.patients,
                    getWeekStartDate(new Date(data.date)),
                    getWeekEndDate(new Date(data.date))
                );

                this.storeService.setScheduler(result.data);
                this.storeService.setLoadingState(false);
            });
    }
    updateTherapistAvailability(data: any) {
        return this.http.post(
            `${baseUrl}/availability/updateMany`,
            { availabilities: data },
            {
                withCredentials: true,
            }
        );
    }

    getTotalBlockedMinutes(allTimeBlocks: any[]): number {
        return allTimeBlocks.reduce((total, timeBlock) => {
            return total + (timeBlock.plannedTx || 0);
        }, 0);
    }

    /**
     * This function creates data for caseload section in scheduler
     * This updates individual notes with their assigned therapist
     * @param data - current caseload object
     * @returns - Caseload with mapped assigned therapist and documentation
     */
    buildCaseloadData(data) {
        for (let index = 0; index < data.notes.dailyNotes?.length; index++) {
            const dailyNote = data.notes.dailyNotes[index];
            dailyNote.therapyAdmissions = [
                {
                    assignedTherapists: [
                        {
                            therapist: {
                                _id: dailyNote.docId.assignedTherapist.therapist,
                                availability: dailyNote.docId.assignedTherapist.availability,
                            },
                            role: dailyNote.docId.assignedTherapist.role,
                        },
                    ],
                    documentation: {
                        dailyNote: [
                            {
                                day: dailyNote.docId.day,
                                _id: dailyNote.docId._id,
                                plannedTx: dailyNote.plannedTx,
                                isSplit: dailyNote.docId.isSplit,
                                plannedDay: dailyNote.docId.plannedDay?._id,
                            },
                        ],
                    },
                    _id: dailyNote.docId.therapy_admission_id,
                },
            ];
            dailyNote.day = dailyNote.docId.day;
            data.notes.dailyNotes[index] = dailyNote;
        }
        for (let index = 0; index < data.notes.progressNotes?.length; index++) {
            const progressNotes = data.notes.progressNotes[index];
            progressNotes.therapyAdmissions = [
                {
                    assignedTherapists: [
                        {
                            role: progressNotes.docId.assignedTherapist.role,
                            therapist: {
                                _id: progressNotes.docId.assignedTherapist.therapist,
                                availability: progressNotes.docId.assignedTherapist.availability,
                            },
                        },
                    ],
                    documentation: {
                        progressNote: [
                            {
                                day: progressNotes.docId.day,
                                _id: progressNotes.docId._id,
                                plannedTx: progressNotes.plannedTx,
                            },
                        ],
                    },
                    _id: progressNotes.docId.therapy_admission_id,
                },
            ];
            progressNotes.day = progressNotes.docId.day;
            data.notes.progressNotes[index] = progressNotes;
        }
        for (let index = 0; index < data.notes.dischargeNotes?.length; index++) {
            const dischargeNotes = data.notes.dischargeNotes[index];
            dischargeNotes.therapyAdmissions = [
                {
                    assignedTherapists: [
                        {
                            role: dischargeNotes.docId.assignedTherapist.role,
                            therapist: {
                                _id: dischargeNotes.docId.assignedTherapist.therapist,
                                availability: dischargeNotes.docId.assignedTherapist.availability,
                            },
                        },
                    ],
                    documentation: {
                        dischargeNote: [
                            {
                                day: dischargeNotes.docId.day,
                                _id: dischargeNotes.docId._id,
                                plannedTx: dischargeNotes.plannedTx,
                            },
                        ],
                    },
                    _id: dischargeNotes.docId.therapy_admission_id,
                },
            ];
            dischargeNotes.day = dischargeNotes.docId.day;
            data.notes.dischargeNotes[index] = dischargeNotes;
        }
        for (let index = 0; index < data.notes.eval?.length; index++) {
            const evaluation = data.notes.eval[index];
            evaluation.therapyAdmissions = [
                {
                    assignedTherapists: [
                        {
                            role: evaluation.docId.assignedTherapist.role,
                            therapist: {
                                _id: evaluation.docId.assignedTherapist.therapist,
                                availability: evaluation.docId.assignedTherapist.availability,
                            },
                        },
                    ],
                    documentation: {
                        evaluation: [
                            {
                                day: evaluation.docId.day,
                                _id: evaluation.docId._id,
                                plannedTx: evaluation.plannedTx,
                            },
                        ],
                    },
                    _id: evaluation.docId.therapy_admission_id,
                },
            ];
            evaluation.day = evaluation.docId.day;
            data.notes.eval[index] = evaluation;
        }
        for (let index = 0; index < data.notes.recertificationNotes?.length; index++) {
            const recertificationNotes = data.notes.recertificationNotes[index];
            recertificationNotes.therapyAdmissions = [
                {
                    assignedTherapists: [
                        {
                            role: recertificationNotes.docId.assignedTherapist.role,
                            therapist: {
                                _id: recertificationNotes.docId.assignedTherapist.therapist,
                                availability: recertificationNotes.docId.assignedTherapist.availability,
                            },
                        },
                    ],
                    documentation: {
                        recertificationNote: [
                            {
                                day: recertificationNotes.docId.day,
                                _id: recertificationNotes.docId._id,
                                plannedTx: recertificationNotes.plannedTx,
                            },
                        ],
                    },
                    _id: recertificationNotes.docId.therapy_admission_id,
                },
            ];
            recertificationNotes.day = recertificationNotes.docId.day;
            data.notes.recertificationNotes[index] = recertificationNotes;
        }

        return data;
    }
}
