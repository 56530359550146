import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { PlanTherapy } from '@app/interfaces';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { PlannedDay, plannerFilters } from '@app/interfaces';
import { formatDateForAuditLog } from '@app/helpers/utils/date.utils';

const baseUrl = `${environment.apiUrl}/plan-therapy`;

@Injectable({
    providedIn: 'root',
})
export class PlanTherapyService {
    calendarData = [];
    ptCertification: any;
    otCertification: any;
    stCertification: any;
    anticipatedDC: any;
    saveInProgress = new BehaviorSubject<boolean>(false);
    constructor(private http: HttpClient) {}

    createPlanTherapy(plan: PlanTherapy, facilityId: string): Observable<any> {
        return this.http.post(`${baseUrl}/${facilityId}`, plan);
    }
    updatePlanTherapy(plan: PlanTherapy, facilityId: string): Observable<any> {
        return this.http.patch(`${baseUrl}/${facilityId}`, plan);
    }
    updatePlannedDay(day: PlannedDay) {
        return this.http.patch(`${environment.apiUrl}/plan-day`, day);
    }
    updatePlannedDayMinutes(day: PlannedDay, id: string) {
        return this.http.patch(`${environment.apiUrl}/plan-day/update-minutes/${id}`, day);
    }
    private plannerPatientFilters$: BehaviorSubject<plannerFilters> = new BehaviorSubject({
        therapyDiscipline: 'all',
        mode: 'ALL',
        payor: 'ALL',
        search: '',
    });
    set plannerPatientFilters(val: Observable<plannerFilters>) {
        val.subscribe((data) => {
            this.plannerPatientFilters$.next(data);
        });
    }

    get plannerPatientFilters(): Observable<plannerFilters> {
        return this.plannerPatientFilters$.asObservable();
    }

    setCalendarData(data: any[]) {
        this.calendarData = data;
        if (this.calendarData?.length) {
            this.ptCertification = this.calendarData[0].ptCertification;
            this.otCertification = this.calendarData[0].otCertification;
            this.stCertification = this.calendarData[0].stCertification;
            this.anticipatedDC = this.calendarData[0].anticipatedDC;
        }
    }

    getCalendarData(): any[] {
        return this.calendarData;
    }

    getTooltipData(date: Date | string) {
        const tooltipData = [];
        if (this.ptCertification) {
            if (new Date(date).setHours(0, 0, 0, 0) === new Date(this.ptCertification?.fromDate).setHours(0, 0, 0, 0)) {
                this.updateDiscipline(tooltipData, 'PT', 'From')
            }
            if (new Date(date).setHours(0, 0, 0, 0) === new Date(this.ptCertification?.throughDate).setHours(0, 0, 0, 0)) {
                this.updateDiscipline(tooltipData, 'PT', 'Thru')
            }
        }
        if (this.otCertification) {
            if (new Date(date).setHours(0, 0, 0, 0) === new Date(this.otCertification?.fromDate).setHours(0, 0, 0, 0)) {
                this.updateDiscipline(tooltipData, 'OT', 'From')
            }
            if (new Date(date).setHours(0, 0, 0, 0) === new Date(this.otCertification?.throughDate).setHours(0, 0, 0, 0)) {
                this.updateDiscipline(tooltipData, 'OT', 'Thru')
            }
        }
        if (this.stCertification) {
            if (new Date(date).setHours(0, 0, 0, 0) === new Date(this.stCertification?.fromDate).setHours(0, 0, 0, 0)) {
                this.updateDiscipline(tooltipData, 'ST', 'From')
            }
            if (new Date(date).setHours(0, 0, 0, 0) === new Date(this.stCertification?.throughDate).setHours(0, 0, 0, 0)) {
                this.updateDiscipline(tooltipData, 'ST', 'Thru')
            }
        }
        if (this.anticipatedDC) {
            if (this.anticipatedDC?.pt && new Date(date).toDateString() === new Date(this.anticipatedDC.pt).toDateString()) {
                this.updateToolTipForAnticipatedDate(tooltipData, 'PT', this.anticipatedDC.pt)
            }
            if (this.anticipatedDC?.ot && new Date(date).toDateString() === new Date(this.anticipatedDC.ot).toDateString()) {
                this.updateToolTipForAnticipatedDate(tooltipData, 'OT', this.anticipatedDC.ot)
            }
            if (this.anticipatedDC?.st && new Date(date).toDateString() === new Date(this.anticipatedDC.st).toDateString()) {
                this.updateToolTipForAnticipatedDate(tooltipData, 'ST', this.anticipatedDC.st)
            }
        }
        return tooltipData;
    }

    updateDiscipline(dataArray, discipline: string, cert: string) {
        const index = dataArray.findIndex(item => item.discipline === discipline);
        const data = {
            discipline: discipline,
            cert: cert,
            progressNote: null
        };
        if (index !== -1) {
            dataArray[index] = data;
        } else {
            dataArray.push(data);
        }
    }
    updateToolTipForAnticipatedDate(dataArray, discipline: string, anticipatedDate: any) {
        anticipatedDate = formatDateForAuditLog(anticipatedDate)
        const index = dataArray.findIndex(item => item.discipline === discipline);
        const data = {
            discipline: discipline,
            anticipatedDate: anticipatedDate,
            progressNote: null
        };
        if (index !== -1) {
            dataArray[index].anticipatedDate = anticipatedDate;
        } else {
            dataArray.push(data);
        }
    }

    adjustTooltip(event: MouseEvent) {
        const tooltip = (event.target as HTMLElement).querySelector('.progress-note-hover-wrapper') as HTMLElement;
        if (!tooltip) {
            return;
        }
        const margin = 10; // Constant margin from left
        // Reset previous stylings
        tooltip.style.left = '';
        tooltip.style.right = '';
        tooltip.style.top = '';
        tooltip.style.bottom = '-19px';

        // Get latest positions
        const tooltipRect = tooltip.getBoundingClientRect();
        const viewportWidth = window.innerWidth;
      
        // Dynamically handle horizontal overflow
        // If tooltip overflows right, adjust it to the left side
        if (tooltipRect.right > viewportWidth) {
            tooltip.style.left = 'unset';
            tooltip.style.right = 'unset';
            const overflowRight = tooltipRect.right - viewportWidth;
            tooltip.style.left = `${-overflowRight-margin}px`;
        }
      } 
}
