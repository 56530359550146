import { Component, Inject, OnInit } from '@angular/core';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { DialogRef } from '@app/services';

@Component({
    selector: 'app-plan-confirmation-modal',
    templateUrl: './plan-confirmation-modal.component.html',
    styleUrls: ['./plan-confirmation-modal.component.scss'],
})
export class PlanConfirmationModalComponent implements OnInit {
    heading = 'Confirm Plan';
    message = '';
    plan: any;
    button1Text = 'Yes';
    button2Text = 'No';
    frequency = '';
    counter = 0;
    weeklyComments: Record<string, unknown>;
    therapists: Record<string, unknown>;
    durationIsSame: boolean;
    previousFrequency: number;
    dataIsSame: boolean;
    ADDisSame: boolean;
    effectiveFromIsSame: boolean;
    endOfPlanIsSame: boolean;

    constructor(@Inject(DIALOG_DATA) private data, private dialogRef: DialogRef) {
        this.plan = data?.plan;
        this.weeklyComments = data?.weeklyComments;
        this.therapists = data?.therapists;
        this.durationIsSame = data?.durationIsSame;
        this.previousFrequency = data?.previousFrequency;
        this.ADDisSame = data?.ADDisSame;
        this.effectiveFromIsSame = data?.effectiveFromIsSame;
        this.endOfPlanIsSame = data?.endOfPlanIsSame;
    }

    ngOnInit(): void {
        if (this.plan?.frequency?.mode) {
            Object.keys(this.plan.frequency.mode).forEach((day) => {
                if (this.plan.frequency.mode[day]) {
                    this.counter = this.counter + 1;
                    const capitalizedDay = day.charAt(0).toUpperCase() + day.slice(1);
                    this.frequency = this.frequency + capitalizedDay + ', ';
                }
            });
            // Removing comma from last lament
            if (this.frequency.length > 2) {
                this.frequency = this.frequency.slice(0, -2);
            }
        }
        this.dataIsSame = !this.dataIsChanged();
    }

    deleteAction(isDeleted) {
        this.dialogRef.close(isDeleted);
    }

    dataIsChanged(): boolean {
        // this.plan.effectiveFrom !== '' ||
        //     this.plan.endOfPlan !== '' ||
        return (
            this.previousFrequency !== this.counter ||
            !this.durationIsSame ||
            !!this.therapists.supervisingTherapist ||
            !!this.therapists.evaluatingTherapist ||
            !!this.therapists.treatingTherapist ||
            !!this.weeklyComments ||
            !this.ADDisSame ||
            !this.effectiveFromIsSame ||
            !this.endOfPlanIsSame
        );
    }
}
