import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { FacilityAdmission, TherapyAdmission } from '@app/interfaces';
import { Store } from '@ngrx/store';
import { PatientState } from '@app/store/store.interface';
import { therapyadmissionRemovedAction } from '@app/store/actions/index';
import { findTherapyCaseByParamId } from '@app/helpers/utils/therapy-case.utils';
import { cloneDeep } from 'lodash';

const baseUrl = `${environment.apiUrl}/therapy-admission`;

@Injectable({ providedIn: 'root' })
export class TherapyAdmissionService {
    tab$: Observable<any>;
    patient: PatientState;
    facilityAdmission: FacilityAdmission;
    constructor(
        private http: HttpClient,
        private store: Store<{ patient: PatientState; facilityAdmission: FacilityAdmission }>,
        private route: ActivatedRoute
    ) {
        this.store.select('facilityAdmission').subscribe((facilityAdmission: FacilityAdmission) => {
            this.facilityAdmission = facilityAdmission;
        });
    }

    therapyAdmissionSave(therapyAdmissionData: TherapyAdmission, facilityId) {
        therapyAdmissionData.facilityAdmission = this.facilityAdmission.id;
        therapyAdmissionData.planTherapy = null;
        return this.http.post<any>(`${baseUrl}/${facilityId}`, therapyAdmissionData, { withCredentials: true }).pipe(
            map((therapyAdmission) => {
                return therapyAdmission;
            })
        );
    }

    therapyAdmissionUpdate(therapyAdmissionData: TherapyAdmission | any, facilityId, nextUrl = '') {
        !therapyAdmissionData.facilityAdmission
            ? (therapyAdmissionData.facilityAdmission = this.facilityAdmission.id)
            : '';
        return this.http
            .patch<any>(`${baseUrl}/${facilityId}` + nextUrl, therapyAdmissionData, { withCredentials: true })
            .pipe(
                map((therapyAdmission) => {
                    return therapyAdmission.data;
                })
            );
    }

    getAllAdmissions(facilityId) {
        return this.http.get<any>(`${baseUrl}/planner/all/${facilityId}`, { withCredentials: true }).pipe(
            map((therapyAdmission) => {
                return therapyAdmission.data;
            })
        );
    }

    getAllPatients(facilityId) {
        return this.http.get<any>(`${baseUrl}/planner/patients/${facilityId}`, { withCredentials: true }).pipe(
            map((therapyAdmission) => {
                return therapyAdmission.data;
            })
        );
    }
    getTherapistAvailability(therapistId, day, docType, noteId) {
        return this.http
            .get<any>(`${baseUrl}/checkAvailability/${day}/${therapistId}/${docType}/${noteId}`, {
                withCredentials: true,
            })
            .pipe(
                map((result) => {
                    return result.data;
                })
            );
    }

    getAdmissionOnFacilities(selectedFacility: string) {
        return this.http.get<any>(`${baseUrl}/facility/` + selectedFacility, { withCredentials: true }).pipe(
            map((therapyAdmission) => {
                return therapyAdmission.data;
            })
        );
    }

    getTherapyAdmission(id: string) {
        return this.http.get<any>(`${baseUrl}/` + id, { withCredentials: true }).pipe(
            map((therapyAdmission) => {
                return therapyAdmission.data;
            })
        );
    }
    therapyadmissionRemovedService() {
        this.store.dispatch(therapyadmissionRemovedAction());
    }
    deleteTherapyAdmission(id: string) {
        return this.http.delete<any>(`${baseUrl}/` + id);
    }
    closeTherapyAdmission(id: string) {
        return this.http.post<any>(`${baseUrl}/close/` + id, {});
    }
    patientsSchedulerConflicts(data: any) {
        return this.http.post<any>(`${baseUrl}/scheduler/conflicts`, data);
    }

    initCurrentTherapyAdmissionByIdQueryString(therapyAdmissions): TherapyAdmission {
        try {
            const queryString = this.route.snapshot.queryParams;
            const currentTherapyAdmission = findTherapyCaseByParamId(therapyAdmissions, queryString['therapy-case']);
            return cloneDeep(currentTherapyAdmission);
        } catch (err) {
            console.log('initCurrentTherapyAdmissionByIdQueryString Error ==>', err.message);
            return;
        }
    }

    getLatestCertificationDate(therapyAdmission) {
        const docs = [
            therapyAdmission?.documentation?.evaluation,
            ...(Array.isArray(therapyAdmission?.documentation?.recertificationNote)
                ? therapyAdmission.documentation.recertificationNote
                : []),
            ...(Array.isArray(therapyAdmission?.documentation?.updatedPlanOfCareNote)
                ? therapyAdmission.documentation.updatedPlanOfCareNote
                : []),
        ].filter((obj: any) => obj.status === 'Completed');

        const latestDoc =
            docs.length &&
            docs.reduce((prev: any, current: any) => {
                const date1 = new Date(new Date(prev.signed.signDate)).toISOString();
                const date2 = new Date(new Date(current.signed.signDate)).toISOString();
                return date1 > date2 ? prev : current;
            });

        return latestDoc?.planOfCare
            ? {
                  from: latestDoc?.planOfCare?.certification?.fromDate,
                  through: latestDoc?.planOfCare?.certification?.throughDate,
              }
            : {
                  from: latestDoc?.plan?.certification?.fromDate,
                  through: latestDoc?.plan?.certification?.throughDate,
              };
    }
}
