/* eslint-disable spellcheck/spell-checker */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTER_UTILS } from '@app/helpers';
import { AuthPolicies, isUserHaveRole } from '@app/helpers/utils';
import {
    AppService,
    AuthService,
    FacilityManagementService,
    PhysicianService,
    UserManagementService,
    UserService,
} from '@app/services';
import { TherapistService } from '@app/services/therapist.service';
import { BASE_ROLES } from '../constants';
import { Licensure } from '@app/interfaces';
import { IActivityRate } from '@app/models';
import { DiffCellRendererComponent } from '@app/data-grid/cell-renderers';
import { cloneDeep } from 'lodash';
import { employmentDates, PBJDetails } from '../../../../interfaces/user.interface';

@Component({
    selector: 'app-view-user',
    templateUrl: './view-user.component.html',
    styleUrls: ['./view-user.component.scss'],
})
export class ViewUserComponent implements OnInit {
    auditLogSide: boolean;
    hideBtn = true;
    auditLogs = [];
    loadingObj: any;
    showForm = false;
    auditLogSelected = 0;
    public SKELETON_CSS = {
        CELL: {
            width: '96%',
            height: '3.4em',
            margin: '0 2em 0.1em 2em',
        },
    };
    userId: string;
    isTherapist = false;
    isPhysician = false;
    isAllowedToView = false;
    isAllowedToEdit = false;
    applyPrintWidth = false;
    activityRates: IActivityRate[] = [];
    masterCopyObject = [];
    editUserRoute =
        '/' +
        ROUTER_UTILS.config.admin.root +
        '/' +
        ROUTER_UTILS.config.admin.accounts.root +
        '/' +
        ROUTER_UTILS.config.admin.accounts.editUser;
    changePasswordRoute =
        '/' +
        ROUTER_UTILS.config.admin.root +
        '/' +
        ROUTER_UTILS.config.admin.accounts.root +
        '/' +
        ROUTER_UTILS.config.admin.accounts.changePassword;
    profileInformation = {
        title: 'Profile Information',
        cols: [
            {
                headerName: 'Name',
                width: 150,
                valueGetter: (v) => {
                    return `${v.data.lastName}, ${v.data.firstName}`;
                },
            },
            {
                headerName: 'Email',
                field: 'email',
                width: 300,
                cellRenderer: DiffCellRendererComponent,
            },
            {
                headerName: 'Employee ID',
                field: 'employeeId',
            },
        ],
        data: [],
    };

    assignedFacilities = {
        title: 'Assigned Facilities',
        cols: [
            {
                headerName: 'Facility Name',
                field: 'facilityId.facilityName',
            },
            {
                headerName: 'Roles',
                cellRenderer: (params) => {
                    let template = '';
                    const terminatedUserPill = params.data.isTerminatedUser ? 'terminated-user-role-pills' : '';
                    params.data.roles.forEach((role) => {
                        template += `<span><span class="mr-3 badge p-1 px-3 rounded-pill text-secondary border d-inline-block align-middle ${
                            terminatedUserPill ? terminatedUserPill : 'border-secondary'
                        }" style='font-weight:400; background-color: #e2e7f3'>${role.name}</span></span>`;
                    });
                    return template;
                },
            },
        ],
        data: [],
        isTerminatedUser: false,
    };

    therapistDetails: any = {};
    physicianDetails: any = { notifyViaSms: false, notifyViaEmail: false };

    licenseDetails = {
        title: 'License Details',
        cols: [
            {
                headerName: 'License No.',
                field: 'licensureNumber',
            },
            {
                headerName: 'State',
                field: 'state',
            },
            {
                headerName: 'Expiration Date',
                field: 'expirationDate',
                cellRenderer: (params) => {
                    if (!params.value) return '';
                    const date = new Date(params.value);
                    return date.toLocaleDateString();
                },
            },
        ],
    };
    licensures: Licensure[];
    roleFacilityMappings = [];
    isSuperAdmin = false;
    isProfileUserSuperAdmin = false;
    userFacilities: any[] = [];
    PBJDetailsData: PBJDetails;
    employmentDatesData: employmentDates;
    constructor(
        private route: ActivatedRoute,
        private userService: UserService,
        private therapistService: TherapistService,
        private router: Router,
        private physicianService: PhysicianService,
        private authService: AuthService,
        private appService: AppService,
        private userManagementService: UserManagementService,
        private facilityManagementService: FacilityManagementService
    ) {}

    ngOnInit(): void {
        this.userManagementService.initializeAllFormStates();
        this.appService.appLoader$.next(true);
        this.isAllowedToView = this.authService.applyPolicy(AuthPolicies.Users.View);
        this.route.queryParamMap.subscribe((queryParams) => {
            this.userId = queryParams.get('id');
            this.isAllowedToView ? this.getUser() : this.getProfile();
            this.isAllowedToEdit =
                this.authService.applyPolicy(AuthPolicies.Users.Edit) 
        });
        this.isSuperAdmin = this.authService.isSuperAdmin;
        if (this.isSuperAdmin) {
            const currentFacilities = this.facilityManagementService._Facilities.value;
            const facilities = new Set();
            currentFacilities.forEach((facility) => facilities.add(facility.facilityName));
            this.roleFacilityMappings = Array.from(facilities);
        }
    }

    getUser() {
        this.userService.getById(this.userId).subscribe({
            next: (data) => this.initForm(data),
            error: () => this.appService.appLoader$.next(false),
        });
    }

    getProfile() {
        this.userService.getCurrentUser(this.userId).subscribe({
            next: (data) => this.initForm(data),
            error: () => this.appService.appLoader$.next(false),
        });
    }

    changePassword() {
        this.router.navigateByUrl(`${this.changePasswordRoute}?id=${this.userId}`);
    }

    initForm(data) {
        this.PBJDetailsData=data.PBJDetails?data.PBJDetails:null;
        this.employmentDatesData=data.employmentDates?data.employmentDates:null;
        this.activityRates = data?.activityRates ? data.activityRates : [];
        this.isProfileUserSuperAdmin =
            data?.roles?.length && data.roles?.findIndex((role) => role.baseRole === BASE_ROLES.SUPER_ADMIN) !== -1;
        const isLoggedInUserSuperAdmin = this.authService.isSuperAdmin;
        if (this.isSuperAdmin && !isLoggedInUserSuperAdmin) this.isAllowedToEdit = false;
        this.profileInformation.data = [data];
        if (this.profileInformation.data[0]?.userAudit?.length > 0) {
            // reverse the userAudit to view the latest edit log on top of the list.
            this.profileInformation.data[0]?.userAudit.reverse();
        }
        this.masterCopyObject = cloneDeep(this.profileInformation.data);
        // this.assignedFacilities.data = { facilities: data.userRoles?.[0]?.facilities, isTerminatedUser: !data.isActive }
        this.assignedFacilities.isTerminatedUser = !data.isActive;
        if (data && data.userRoles && data.userRoles?.[0] && data.userRoles?.[0]?.facilities) {
            let facilities = data.userRoles?.[0]?.facilities.map((elem) => ({
                ...elem,
                isTerminatedUser: !data.isActive,
            }));
            if (this.isSuperAdmin) {
                facilities = facilities.filter((facility) => {
                    const facilityName = facility.facilityId?.facilityName;
                    return this.roleFacilityMappings.includes(facilityName);
                });
            }
            this.assignedFacilities.data = facilities;
        } else this.assignedFacilities.data = [];
        this.isTherapist = isUserHaveRole(BASE_ROLES.THERAPIST, data.userRoles);
        this.isPhysician = isUserHaveRole(BASE_ROLES.PHYSICIAN, data.userRoles);
        if (this.isTherapist) {
            this.therapistService.getTherapist(data._id).subscribe({
                next: (therapistData) => {
                    if (therapistData) {
                        this.therapistDetails = therapistData;
                        this.licensures = therapistData.licensures;
                    }
                    this.showForm = true;
                    this.appService.appLoader$.next(false);
                },
                error: () => this.appService.appLoader$.next(false),
            });
        } else if (this.isPhysician) {
            this.physicianService.getPhysician(data._id).subscribe({
                next: (physicianData) => {
                    if (physicianData) {
                        this.physicianDetails = physicianData;
                    }
                    this.showForm = true;
                    this.appService.appLoader$.next(false);
                },
                error: () => this.appService.appLoader$.next(false),
            });
        } else {
            this.showForm = true;
            this.appService.appLoader$.next(false);
        }
    }
    prepareChangeInstanceData(index: number) {
        this.auditLogSelected = index;
        const changeHistory = this.profileInformation?.data[0]?.userAudit[index];
        this.profileInformation.data.map((data) => {
            data.email = {
                currentValue: changeHistory.changeFields[0].currentValue,
                previousValue: changeHistory.changeFields[0].previousValue,
            };
        });
        this.profileInformation.data = [...this.profileInformation.data];
    }
    editUser() {
        this.router.navigateByUrl(`${this.editUserRoute}?id=${this.userId}`);
    }
    openAuditLog() {
        this.auditLogSide = true;
        this.hideBtn = false;
        this.prepareChangeInstanceData(0);
    }
    closeHistorySideBar(event: boolean) {
        this.auditLogSide = event;
        this.hideBtn = true;
        this.profileInformation.data = cloneDeep(this.masterCopyObject);
    }
    print() {
        this.applyPrintWidth = true;
        setTimeout(() => print(), 1000);
        window.onafterprint = () => {
            this.applyPrintWidth = false;
        };
    }
    getFacilityName(id) {
        return this.assignedFacilities.data.find((facility) => facility?.facilityId?._id === id)?.facilityId
            ?.facilityName;
    }
    facilityExists(id) {
        return this.assignedFacilities.data.findIndex((facility) => facility?.facilityId?._id === id) !== -1;
    }
}
