<app-base-modal-card
    [heading]="heading"
    id="unassign-patient"
    wrapperClass="modal-semi-medium"
    (close)="deleteAction(false)"
>
    <div body class="p-3 rounded" *ngIf="plan">
        <p *ngIf="!dataIsSame" class="d-flex flex-row body-large-semibold text-secondary">
            Are you sure you want to plan with following details?
        </p>
        <p *ngIf="dataIsSame" class="d-flex flex-row body-large-semibold text-secondary">
            Saving this plan will update any schedule changes that have been made for this patient. Do you want to continue?
        </p>
        <!-- <div *ngIf="weeklyComments" class="d-flex mb-2">
            <div class="d-flex flex-row body-medium-semibold text-secondary">
                <div class="label">Appointment Time: </div>
                <div class="body-medium">
                    <ng-container *ngFor="let comment of weeklyComments | keyvalue">
                        <span *ngIf="comment.value.plannedTx" class="d-block">
                            {{ comment.key | titlecase }} at {{ comment.value.startTime | date: 'hh:mm a' }}
                        </span>
                    </ng-container>
                </div>
            </div>
        </div> -->
        <!-- <div *ngIf="therapists?.supervisingTherapist" class="d-flex mb-2">
            <div class="d-flex flex-row body-medium-semibold text-secondary">
                <div class="label">Supervising Therapist: </div>
                <div class="body-medium">{{therapists?.supervisingTherapist?.name}}</div>
            </div>
        </div>
        <div *ngIf="therapists?.evaluatingTherapist" class="d-flex mb-2">
            <div class="d-flex flex-row body-medium-semibold text-secondary">
                <div class="label">Evaluating Therapist: </div>
                <div class="body-medium">{{therapists?.evaluatingTherapist?.name}}</div>
            </div>
        </div>
        <div *ngIf="therapists?.treatingTherapist" class="d-flex mb-2">
            <div class="d-flex flex-row body-medium-semibold text-secondary">
                <div class="label">Treating Therapist: </div>
                <div class="body-medium">{{therapists?.treatingTherapist?.name}}</div>
            </div>
        </div> -->
        <!-- *ngIf="!durationIsSame || previousFrequency !== counter" -->
        <div class="d-flex mb-2">
            <div *ngIf="this.counter" class="d-flex flex-row body-medium-semibold text-secondary">
                <div class="label">Frequency: </div>
                <div class="body-medium">{{ ' ' + this.counter + 'x (' + frequency + ') ' }}&nbsp;</div>
                <!-- <span class="body-medium" style="padding-left: 117px"></span> -->
            </div>
            <div *ngIf="plan && plan.duration" class="d-flex flex-row body-medium text-secondary">
                for <span class="pl-1 body-medium">{{ ' ' + plan.duration + ' Weeks' }}</span>
            </div>
        </div>
        <!-- *ngIf="plan.effectiveFrom" -->
        <div class="d-flex flex-row body-medium-semibold text-secondary mb-2">
            <div class="label">Effective from: </div>
            <div class="body-medium">{{ ' ' + plan.effectiveFrom | date }}</div>
        </div>
        <!-- *ngIf="plan.endOfPlan" -->
        <div class="d-flex flex-row body-medium-semibold text-secondary mb-2">
            <div class="label">End of plan: </div>
            <div class="body-medium">{{ ' ' + plan.endOfPlan | date }}</div>
        </div>
        <div *ngIf="plan.anticipatedDischargeDate" class="d-flex flex-row body-medium-semibold text-secondary">
            <div class="label">Anticipated DC Date: </div>
            <div class="body-medium">{{ ' ' + plan.anticipatedDischargeDate | date }}</div>
        </div>
    </div>
    <div footer class="d-flex flex-row col-12 justify-content-end pl-0 pr-0">
        <button class="btn btn-secondary innova-btn mr-2 btn-danger" (click)="deleteAction(false)">
            {{ button2Text }}
        </button>
        <button class="btn innova-btn btn-secondary" (click)="deleteAction(true)">
            {{ button1Text }}
        </button>
    </div>
</app-base-modal-card>
